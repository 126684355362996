import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import Logo from '../assets/logotype.png';
import { Link } from 'react-router-dom';
import Hero from '../assets/reviews.png';
import Selected from '../assets/list_selected.png';
import List from '../assets/list.png';
import axios from 'axios';
import errorNotify, { successNotify } from '../services/notify';
import { API_URL } from '../http';


const SLF = () => {

  const [practics, setPractics] = useState(true);
  const [checked, setChecked] = useState(1);
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');      
  const [model, setModel] = useState(0);
  const [typePayment, setTypePayment] = useState(1);
  const courseName = '«Соединение Миров»';
  const currentYear = new Date().getFullYear();
  const OneSum = 3000000;
  const OneSumDecorative = '30 000';
  const TwoSum = 12000000;
  const TwoSumDecorative = '120 000';
  const TwoSumDeposit = 1500000;
  const TwoSumDepositDecorative = '15 000';
  const TwoSumOther = 10500000;
  const TwoSumOtherDecorative = '105 000';
  const ThreeSum = 10000000;
  const ThreeSumDecorative = '100 000';
  const ThreeSumDeposit = 1500000;
  const ThreeSumDepositDecorative = '15 000';
  const ThreeSumOhter = 8500000;
  const ThreeSumOhterDecorative = '85 000';
  const [buyModalOne, setBuyModalOne] = useState(true);
  const [buyModalTwo, setBuyModalTwo] = useState(true);
  const [buyModalThree, setBuyModalThree] = useState(true);

  function ShowModalBuyOne() {
    setBuyModalOne(!buyModalOne);
    if(buyModalOne) document.getElementById("modalPurchaseOne").style.display = "block";
    else document.getElementById("modalPurchaseOne").style.display = "none";
  }
  function ShowModalBuyTwo() {
    setBuyModalTwo(!buyModalTwo);
    if(buyModalTwo) document.getElementById("modalPurchaseTwo").style.display = "block";
    else document.getElementById("modalPurchaseTwo").style.display = "none";
  }
  function ShowModalBuyThree() {
    setBuyModalThree(!buyModalThree);
    if(buyModalThree) document.getElementById("modalPurchaseThree").style.display = "block";
    else document.getElementById("modalPurchaseThree").style.display = "none";
  }

  function showpractics() {
    setPractics(!practics);
    if(practics) document.getElementById("footerdrop").style.display = "flex";
    else document.getElementById("footerdrop").style.display = "none";
  }

  function initPaymentOne() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    let purchaseOption = 1;
    let itemAmount = OneSum;
    let itemName = courseName + ' (Понедельно)';
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }
  function initPaymentTwo() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    let purchaseOption;
    let itemAmount;
    let itemName;
    if (typePayment === 1) {
      itemAmount = TwoSum;
      itemName = courseName;
      purchaseOption = 2;
    }
    if (typePayment === 2) {
      itemAmount = TwoSumDeposit;
      itemName = courseName + ' - депозит';
      purchaseOption = 1;
    }
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }
  function initPaymentThree() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    let purchaseOption;
    let itemAmount;
    let itemName;
    if (typePayment === 1) {
      itemAmount = ThreeSum;
      itemName = courseName + ' (Для практиков «Сияющие руки»)';
      purchaseOption = 2;
    }
    if (typePayment === 2) {
      itemAmount = ThreeSumDeposit;
      itemName = courseName + ' (Для практиков «Сияющие руки») - депозит';
      purchaseOption = 1;
    }
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }

    return (
      <>
          <div id="modalPurchaseOne" className="modal-buy">
            <div className="modal-content">
              <span onClick={() => ShowModalBuyOne()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Соединение Миров»</h3>
              <hr className="modal-feedback-hr" />
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Курс:</p>
                  <p className='card_buy-confirm-text'>«Соединение Миров»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>29.09.2023</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>29.10.2023</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{OneSumDecorative + ' ₽'} <span style={{fontSize: '12px', color: 'gray'}}>(Понедельно)</span></p>
                </div>
              </div>
              <button onClick={() => initPaymentOne()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
          <div id="modalPurchaseTwo" className="modal-buy">
            <div className="modal-content">
              <span onClick={() => ShowModalBuyTwo()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Соединение Миров»</h3>
              <hr className="modal-feedback-hr" />
              <p className="modal-title">Модель оплаты</p>
              { typePayment === 1 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment">Внести депозит</button>
              </div>
              : typePayment === 2 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment_alt">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment_alt">Внести депозит</button>
              </div>
              : null
              }
              { typePayment === 2 ?
                <div className='disclamer-payment-box'>
                  <p>Вы вносите депозит в размере 15 000 руб; остаток – {TwoSumOtherDecorative}. Оставшуюся сумму необходимо внести за 2 дня до начала курса</p>
                </div>
                : null
              }
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Курс:</p>
                  <p className='card_buy-confirm-text'>«Соединение Миров»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>29.09.2023</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>29.10.2023</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{typePayment === 1 ? TwoSumDecorative + ' ₽' : typePayment === 2 ? TwoSumDepositDecorative + ' ₽' : 'Неизвестно'}</p>
                </div>
              </div>
              <button onClick={() => initPaymentTwo()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
          <div id="modalPurchaseThree" className="modal-buy">
            <div className="modal-content">
              <span onClick={() => ShowModalBuyThree()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Соединение Миров»</h3>
              <hr className="modal-feedback-hr" />
              <p className="modal-title">Модель оплаты</p>
              { typePayment === 1 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment">Внести депозит</button>
              </div>
              : typePayment === 2 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment_alt">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment_alt">Внести депозит</button>
              </div>
              : null
              }
              { typePayment === 2 ?
                <div className='disclamer-payment-box'>
                  <p>Вы вносите депозит в размере 15 000 руб; остаток – {ThreeSumOhterDecorative}. Оставшуюся сумму необходимо внести за 2 дня до начала курса</p>
                </div>
                : null
              }
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Курс:</p>
                  <p className='card_buy-confirm-text'>«Соединение Миров»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>29.09.2023</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>29.10.2023</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{typePayment === 1 ? ThreeSumDecorative + ' ₽' : typePayment === 2 ? ThreeSumDepositDecorative + ' ₽' : 'Неизвестно'}</p>
                </div>
              </div>
              <button onClick={() => initPaymentThree()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
      <section className="desc-landing">
        <h2 className='course-description-header'><span>Что ждет вас</span> на этом курсе?</h2>
        
      <div className="container">
        <div className="courses-block">

          <div className='card-spacing'>

            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Тонкие планы развоплощенных. <br />Кто остаётся, кто переходит.<br />Кто стучится в дверь ко мне? <br />Сны и реальности.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Образ смерти как модель жизни. <br />Что там за горизонтом? Есть ли Свет к конце тоннеля? От чего зависит образ перехода? Нужна ли помощь развоплощенным? <br />– Изменения мерности и процессы 3-9-21-40 дней. <br />– Как это было в Сансаре и как это бывает при свободном выборе.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Цивилизации других галактических систем.<br />Световые коды, дешифровка через цифровые коды.<br />Выход из КА – Меры как возможность допуска к информации любого уровня вне времени, соответственно масштабу расширения сознания.</div>
                </div>
              </div>
            </div>

          </div>

          <div className='card-spacing'>

            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Мотивация устанавливать связи с «перешедшими». Экологичность, корректность и целесообразность</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Версия кванта запрошенного будущего варианта фиксации создания. <br />Световые и графические коды (ключи)</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Цивилизации, развитие человеческой расы и история эволюции тел. Мифы и генетические эксперименты. <br />Кто есть кто (Пегас, Кентавр, Единорог, Дракон и другие).</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Галактики и их представители на планете Земля.<br />Дом, милый Дом.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Временные порталы.<br />Время как материя. Куда «течёт» время? Воспоминания о будущем или мечты о прошлом?</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Святые, признанные религиями</div>
                </div>
              </div>
            </div>

          </div>

          <div className='card-spacing'>

            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Для людей, занимающихся телесно-ориентированными практиками</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Известные личности. Что нужно знать о правах и возможностях контакта. Проекции и ожидания.<br />Разница между регрессивным гипнозом и ченнелингом с Сознанием известных людей. <br />С кем общаются, когда общаются и от чего зависит чистота информации.<br />«Оставьте Пушкина в покое» или как получить доступ к музыке слов и ритмам через ченнелинг.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Горизонтальная иерархия.<br />4 закона рода или к чему стремится родовая система. Незнание не освобождает от исполнения. Как быть богатым наследником своего рода.<br />– Родственники – запрос ресурсов по роду.<br />Каждый на своем месте и со своим подарком для тебя – увидеть и получить.<br />Прямая связь возможна.<br />Где заканчивается родовая история и начинается историческая память. Карма личная, карма рода, карма Земли. Камеры в тюрьме или возможности?</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Физическое тело как образ Космоса на примере иерархии аминокислот.</div>
                </div>
              </div>
            </div>
            
          </div>

          <div className='card-spacing'>

            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Духовная иерархия – вертикальная иерархия.<br />Единые законы иерархии систем на всех уровнях Создания<br />(от аминокислот на уровне формирования ДНК и клетки до Вознесенных Учителей)</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Внимание – эгрегоры. Эгрегоров бояться - на Земле не воплощаться. Право свободной воли согласно Галактическому закону. Карма.<br />Свидетели Кармы, Хранители Кармы.<br />Свобода, завершение, создание, закон Баланса.<br />Эгрегоры и Бог.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Настройка через лингвистические и графические, письма из будущего.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Телепорт для путешествия по временным порталам для нахождения своих отложенных или забытых ресурсов.<br />МерКаба - активация. Особенности исцеления в период квантового перехода. Что такое Меркаба? Это энергетическое кристаллическое поле, которое окружает физическое тело человека.<br />Меркаба единого стандарта и размера 16,7 метров?<br />Меркаба как образ колесницы или летающего корабля для тела и души, способной перенести человека в параллельный мир или в другое время. Возвращение естественной способности энергетической человеческой природы в следствии активации 12 спиралей ДНК.</div>
                </div>
              </div>
            </div>
            
          </div>
          <div className='card-spacing-sm'>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Тонкие планы развоплощенных. <br />Кто остаётся, кто переходит.<br />Кто стучится в дверь ко мне? <br />Сны и реальности.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Образ смерти как модель жизни. <br />Что там за горизонтом? Есть ли Свет к конце тоннеля? От чего зависит образ перехода? Нужна ли помощь развоплощенным? <br />– Изменения мерности и процессы 3-9-21-40 дней. <br />– Как это было в Сансаре и как это бывает при свободном выборе.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Цивилизации других галактических систем.<br />Световые коды, дешифровка через цифровые коды.<br />Выход из КА – Меры как возможность допуска к информации любого уровня вне времени, соответственно масштабу расширения сознания.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Мотивация устанавливать связи с «перешедшими». Экологичность, корректность и целесообразность</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Версия кванта запрошенного будущего варианта фиксации создания. <br />Световые и графические коды (ключи)</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Цивилизации, развитие человеческой расы и история эволюции тел. Мифы и генетические эксперименты. <br />Кто есть кто (Пегас, Кентавр, Единорог, Дракон и другие).</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Галактики и их представители на планете Земля.<br />Дом, милый Дом.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Временные порталы.<br />Время как материя. Куда «течёт» время? Воспоминания о будущем или мечты о прошлом?</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Святые, признанные религиями</div>
                </div>
              </div>
            </div>
          </div>
          <div className='card-spacing-sm'>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Для людей, занимающихся телесно-ориентированными практиками</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Известные личности. Что нужно знать о правах и возможностях контакта. Проекции и ожидания.<br />Разница между регрессивным гипнозом и ченнелингом с Сознанием известных людей. <br />С кем общаются, когда общаются и от чего зависит чистота информации.<br />«Оставьте Пушкина в покое» или как получить доступ к музыке слов и ритмам через ченнелинг.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Горизонтальная иерархия.<br />4 закона рода или к чему стремится родовая система. Незнание не освобождает от исполнения. Как быть богатым наследником своего рода.<br />– Родственники – запрос ресурсов по роду.<br />Каждый на своем месте и со своим подарком для тебя – увидеть и получить.<br />Прямая связь возможна.<br />Где заканчивается родовая история и начинается историческая память. Карма личная, карма рода, карма Земли. Камеры в тюрьме или возможности?</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Физическое тело как образ Космоса на примере иерархии аминокислот.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Духовная иерархия – вертикальная иерархия.<br />Единые законы иерархии систем на всех уровнях Создания<br />(от аминокислот на уровне формирования ДНК и клетки до Вознесенных Учителей)</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Внимание – эгрегоры. Эгрегоров бояться - на Земле не воплощаться. Право свободной воли согласно Галактическому закону. Карма.<br />Свидетели Кармы, Хранители Кармы.<br />Свобода, завершение, создание, закон Баланса.<br />Эгрегоры и Бог.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Настройка через лингвистические и графические, письма из будущего.</div>
                </div>
              </div>
            </div>
            <div className="courses-card">
              <div className="courses-card_item">
                <div className="courses-card_content">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                  </svg>
                  <div className="courses-card_text">Телепорт для путешествия по временным порталам для нахождения своих отложенных или забытых ресурсов.<br />МерКаба - активация. Особенности исцеления в период квантового перехода. Что такое Меркаба? Это энергетическое кристаллическое поле, которое окружает физическое тело человека.<br />Меркаба единого стандарта и размера 16,7 метров?<br />Меркаба как образ колесницы или летающего корабля для тела и души, способной перенести человека в параллельный мир или в другое время. Возвращение естественной способности энергетической человеческой природы в следствии активации 12 спиралей ДНК.</div>
                </div>
              </div>
            </div>
          </div>

        </div> 
      </div>




        <div className="container">
          <h2 className="dark_header limit-header">Варианты <span> участия и стоимость</span></h2>
          <div className="cost-small-box">
            <div className='cost-small-item'>
              <h2 className="title-cost">150 000 руб.</h2>
              <div className='cost-small-item-box'>
                <p className="cost-small-item-box_text">Оплата осуществляется понедельно по 30 000 руб.<br /><br />Если в какой-то момент вы поняли, что дальше идти не готовы, оставшуюся часть суммы вносить не нужно</p>
              </div>
              <a onClick={() => ShowModalBuyOne()}>
                <button className="circle-btn_fill circle-payment-btn-alt">
                  Оплатить
                </button>
              </a>
            </div>   
            <div className='cost-small-item'>
              <h2 className="title-cost">120 000 руб.</h2>
              <div className='cost-small-item-box'>
                <p className="cost-small-item-box_text">Стоимость курса для тех, кто выбрал и готов проходить курс полностью <br/><br/>Если в какой-то момент вы поняли, что дальше идти не готовы, оставшаяся часть суммы не возвращается</p>
              </div>
              <a onClick={() => ShowModalBuyTwo()}>
                <button className="circle-btn_fill circle-payment-btn-alt">
                  Оплатить
                </button>
              </a>
            </div>  
            <div className='cost-small-item'>
              <h2 className="title-cost">100 000 руб.</h2>
              <div className='cost-small-item-box'>
                <p className="cost-small-item-box_text-3">Для практиков «Сияющие руки»</p>
              </div>
              <a onClick={() => ShowModalBuyThree()}>
                <button className="circle-btn_fill circle-payment-btn-alt">
                  Оплатить
                </button>
              </a>
            </div>  

          </div>
          <div className="costs-group">
            <h2 className="title-cost">150 000 руб.</h2>
            <h2 className="title-cost">120 000 руб.</h2>
            <h2 className="title-cost">100 000 руб.</h2>
          </div>
          <div className="cost-desc-group">
            <div className="cost-description">
              <p className='cost-desc-text-1'>Оплата осуществляется понедельно по 30 000 руб.</p>
              <p className='cost-desc-text-1'>Если в какой-то момент вы поняли, что дальше идти не готовы, оставшуюся часть суммы вносить не нужно</p>
            </div>
            <div className="cost-description-2">
              <p className='cost-desc-text-2'>Стоимость курса для тех, кто выбрал и готов проходить курс полностью</p>
              <p className='cost-desc-text-2'>Если в какой-то момент вы поняли, что дальше идти не готовы, оставшаяся часть суммы не возвращается</p>
            </div>
            <div className="cost-description-3">
              <p className='cost-desc-text-3'>Для практиков «Сияющие руки»</p>
            </div>
          </div>
          <div className="group_circle-btn-alt">
            <a onClick={() => ShowModalBuyOne()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a onClick={() => ShowModalBuyTwo()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a onClick={() => ShowModalBuyThree()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
          </div>
        </div>

        <section className="disclamer">
          <div className="disclamer-box">
            <p className='disclamer-box_text_1'><span>Регистрация и бронь на курсе происходит после анкетирования, собеседования, подписания договора</span> и внесения депозита 15 000 руб.</p>
            <p className='disclamer-box_text_2'>Елена Эра оставляет за собой право отказать в участии в курсе (без объяснения причины)</p>
          </div>
        </section>

        <footer>
        <div className="container">
          <div className="footer_block">
            <div className="footer_block-brand">
              <img className="footer_block-logotype" src={Logo} />
              <p>© {currentYear}, Елена Эра. Все права защищены</p>
            </div>
            <div className="sm-footer-info">
              <a>Расписание</a>
              <a onClick={() => showpractics()}>
                Практики
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </a>
              <div id="footerdrop">
                <Link className="footerdrop-item" to="/shining-hands">- Практики метода «Сияющие руки»</Link>
                <Link className="footerdrop-item" to="/">- Практики метода «Соединение миров»</Link>
              </div>
              <a>Мастера</a>
              <a>Книги</a>
              <a>Блог</a>
            </div>
            <div className="footer_block-info">
              <div className="footer_block-links">
                <Link to="/schedule">Расписание</Link>
                <div className="dropup">
                  <button className="dropupbtn">
                    Практики 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                  <div className="dropup-content">
                    <a href="#">Практики метода «Сияющие руки»</a>
                    <a href="#">Практики метода «Соединение миров» </a>
                  </div>
                </div>
                <a href="#3">Мастера</a>
                <a href="#3">Книги</a>
                <a href="#3">Блог</a>
              </div>
              <a className="footer_block-policy" href="#3">Политика конфиденциальонсти</a>
            </div>
          </div>
        </div>
      </footer>
      
      </section>
      </>
    );
};

export default observer(SLF);