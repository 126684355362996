import React from 'react';
import {observer} from "mobx-react-lite";

const SMC = () => {
  return (
    <section className="courses">
      <div className="container">
        <h2 className="dark_header">Про что курс <span>«Соединение Миров»?</span></h2>
        <p className="text-landing-alt">Курс будет содержать <span>инструменты</span> получения доступа к информации, <span>которые я использую с 1990 года.</span></p>
        <p className="text-landing">Процесс работы с некоторыми из них можно наблюдать на вебинарах «Очевидное – невероятное. Вспомнить все». Многие я использую для себя, своей жизни, работы на планете Земля.</p>

      </div>
    </section>
    );
};

export default observer(SMC);